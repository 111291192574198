import React, { FunctionComponent } from 'react';
import { makeStyles } from '@admitkard/ccl/style';
import Image from 'next/image';
interface BlogItemCardProps {
  title: string;
  subtitle: string;
  backgroundColor: string;
  readTime: number;
  blogImg: string;
  blogLink: string;
}

const useStyles = (backgroundColor: string) =>
  makeStyles(
    () => ({
      rootContainer: {
        background: backgroundColor,
        borderRadius: '1rem',
        display: 'flex',
        margin: '0 1rem',
        position: 'relative',
        textDecoration: 'none',
        color: 'inherit',
        overflow:'hidden',
        '@media screen and (min-width: 1200px)': {
          margin: '0',
          borderRadius: '1.75rem',
        },
      },
      blogContentContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '1rem',
        marginLeft: '1rem',
        marginBottom: '1.5rem',
        '@media screen and (min-width: 1200px)': {
          marginTop: '1.5rem',
          marginLeft: '1.5rem',
          marginBottom: '3.5rem',
        },
      },
      blogReadTime: {
        width: 'fit-content',
        backgroundColor: 'white',
        fontWeight: 400,
        fontSize: '0.75rem',
        lineHeight: '1.125rem',
        borderRadius: '0.8125rem',
        padding: '0.25rem 0.625rem',
        marginBottom: '0.75rem',
        '@media screen and (min-width: 1200px)': {
          color: 'hsla(0, 0%, 11%, 1)',
          borderRadius: '2rem',
          marginBottom: '2.25rem',
          fontSize: '1rem',
          lineHeight: '2rem',
          padding: '0.25rem 1rem',
        },
      },
      endIconContainer: {
        height: '100%',
        width: '1.25rem',
        display: 'flex',
        alignItems: 'center',
        marginRight: '1rem',
        marginLeft: 'auto',
        position: 'relative',
      },
      endIconContent: {
        background: 'hsla(0, 0%, 100%, 0.8)',
        height: '1.25rem',
        display: 'grid',
        placeItems: 'center',
        aspectRatio: '1',
        boxSizing: 'border-box',
        borderRadius: '50%',
        paddingLeft: '0.2rem',
        paddingTop: '0.05rem',
        color: 'hsla(270, 100%, 75%, 1)',
        '& svg': {
          color: 'hsla(270, 100%, 75%, 1)',
        },
        '@media screen and (min-width: 1200px)': {
          height: '1.75rem',
          paddingLeft: '0.08rem',
        },
      },
      textContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '2.8125rem',
        '@media screen and (min-width: 1200px)': {
          height: '3.625rem',
        },
      },
      title: {
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: '1.375rem',
        '@media screen and (min-width: 1200px)': {
          fontSize: '1.25rem',
          lineHeight: '1.75rem',
        },
      },
      subtitle: {
        fontSize: '0.875rem',
        lineHeight: '1.375rem',
        '@media screen and (min-width: 1200px)': {
          fontSize: '1rem',
          lineHeight: '1.75rem',
        },
      },
      blogCardImgContainerSmall: {
        position: 'absolute',
        right: '0px',
        bottom: '0px',
        height:'100%',
        '@media screen and (min-width: 1200px)': {
          display:'none',
        },
      },
      blogCardImgContainerLarge: {
        position: 'absolute',
        right: '0px',
        bottom: '0px',
        display:'none',
        height:'100%',
        '@media screen and (min-width: 1200px)': {
          display:'block',
        },
      },
    }),
    { name: 'BlogItemCard' }
  );

const BlogItemCard: FunctionComponent<BlogItemCardProps> = (props) => {
  const classes = useStyles(props.backgroundColor)();

  return (
    <a id="cta-blog" className={classes.rootContainer} href={props.blogLink}>
      <div className={classes.blogContentContainer}>
        <span className={classes.blogReadTime}>{`${props.readTime} min read`}</span>
        <div className={classes.textContainer}>
          <span className={classes.title}>{props.title}</span>
          <span className={classes.subtitle}>{props.subtitle}</span>
        </div>
      </div>
      <div className={classes.blogCardImgContainerSmall}>
        <Image
          height={123}
          width={128}
          src={props.blogImg} />
      </div>
      <div className={classes.blogCardImgContainerLarge}>
        <Image
          height={216}
          width={210}
          src={props.blogImg} />
      </div>
      <div className={classes.endIconContainer}>
        <div className={classes.endIconContent}>
          <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.00632 5.77586C4.08442 5.69776 4.08443 5.57113 4.00632 5.49302L0.247108 1.73381C0.0518459 1.53855 0.0518458 1.22196 0.247108 1.0267L0.364959 0.908851C0.560221 0.713589 0.876803 0.713589 1.07207 0.908851L5.4441 5.28089C5.63937 5.47615 5.63937 5.79273 5.4441 5.988L1.07207 10.36C0.876804 10.5553 0.560221 10.5553 0.364959 10.36L0.247108 10.2422C0.0518457 10.0469 0.0518459 9.73034 0.247108 9.53508L4.00632 5.77586Z"
              fill="#C081FF"
            />
          </svg>
        </div>
      </div>
    </a>
  );
};

export default BlogItemCard;
