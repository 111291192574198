import { makeStyles } from '@admitkard/ccl/style';
import { HeaderLabel } from '../CommonComponents';
import React from 'react';
import BlogItemCard from './BlogItemCard';

const useStyles = makeStyles(
  (theme) => ({
    rootContainer: {
      width: '100%',
      boxSizing: 'border-box',
      fontFamily: 'Poppins, sans-serif !important',
      color: theme.colorPalette.indigo.main,
    },
    itemsContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(361px, 1fr))',
      gap: 'clamp(.25rem, 1vw, 0.5rem)',
      justifyContent: 'center',
    },
    moreButtonMobile: {
      width: 'fit-content',
      borderRadius: '2.1875rem',
      background: '#FAF6FF',
      margin: '0 auto',
      marginTop: '0.75rem',
      padding: '0.5rem 1.25rem',
      '& a': {
        textDecoration: 'none',
      },
      '& span': {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '0.875rem',
        lineHeight: '1.5rem',
        color: '#483287',
      },
      '@media screen and (min-width: 992px)': {
        display: 'none',
      },
    },
    moreButtonDesktop: {
      padding: '0.75rem 1.25rem',
      background: '#FAF6FF',
      borderRadius: '2.1875rem',
      height: 'fit-content',
      '& a': {
        textDecoration: 'none',
      },
      '& span': {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: '1.5rem',
        color: '#483287',
      },
      '@media screen and (max-width: 992px)': {
        display: 'none',
      },
    },
  }),
  { name: 'BlogSection' }
);

const BlogSection: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.rootContainer}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <HeaderLabel label="blogs" />
        <div className={classes.moreButtonDesktop}>
          <a id="cta-blogs" href="/blog">
            <span>view all</span>
          </a>
        </div>
      </div>
      <div id='blog-items' className={classes.itemsContainer}>
        <BlogItemCard
          title="Best SOP for Master (MS)"
          subtitle="in Data Science"
          readTime={22}
          backgroundColor="hsla(270, 100%, 97%, 1)"
          blogLink="/blog/sop-for-data-science/"
          blogImg="https://assets.admitkard.com/images/home-blogs-01.webp"
        />
        <BlogItemCard
          title="PTE score required for"
          subtitle="Australia"
          readTime={9}
          backgroundColor="hsla(29, 100%, 94%, 1)"
          blogLink="/blog/the-ultimate-guide-to-pte-score-required-for-australia/"
          blogImg="https://assets.admitkard.com/images/home-blogs-02.webp"
        />
        <BlogItemCard
          title="Career objective for MBA"
          subtitle="template"
          readTime={10}
          backgroundColor="hsla(163, 62%, 89%, 1)"
          blogLink="/blog/career-objective-for-mba/"
          blogImg="https://assets.admitkard.com/images/home-blogs-03.webp"
        />
        <BlogItemCard
          title="Best 42 courses in Canada"
          subtitle="after 12th"
          readTime={16}
          backgroundColor="hsla(270, 100%, 97%, 1)"
          blogLink="/blog/list-of-top-courses-in-canada-after-12th/"
          blogImg="https://assets.admitkard.com/images/home-blogs-04.webp"
        />
      </div>
      <div className={classes.moreButtonMobile}>
        <a href="/blog">
          <span>more</span>
        </a>
      </div>
    </div>
  );
};

export default BlogSection;
